<script setup lang="ts">
import { getSecondsSinceEpoch } from '~/utils/helpers/dates'

const { data, signOut, getSession, accessToken } = useAuthAdapter()

watch(data, (newData) => {
  if (!newData) {
    return
  }

  if (import.meta.client) {
    scheduleSessionRenovation(newData.accessTokenExpiresAt)
  }

  if (newData.invalidToken) {
    signOut()
  }
}, {
  deep: true,
  immediate: true,
})

async function renovateSession() {
  const newSession = await getSession()
  accessToken.value = newSession.accessToken
}

function scheduleSessionRenovation(timeInSeconds = 0) {
  const secondsToExpiration = timeInSeconds - getSecondsSinceEpoch()
  const millisecondsToExpiration = secondsToExpiration * 1000

  setTimeout(() => {
    renovateSession()
  }, millisecondsToExpiration)
}

useHead({
  titleTemplate: (title) => {
    const websiteTitle = 'Deep Blue Links'
    return title ? `${title} | ${websiteTitle}` : websiteTitle
  },
})
</script>

<template>
  <div>
    <Head>
      <Link
        rel="preconnect"
        href="https://fonts.googleapis.com"
      />
      <Link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossorigin=""
      />
      <Link
        href="https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap"
        rel="stylesheet"
      />
    </Head>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
