// import this after install `@mdi/font` package
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify, type ThemeDefinition } from 'vuetify'

const myCustomLightTheme: ThemeDefinition = {
  dark: true,
  colors: {
    'background': '#03051C',
    'surface': '#1B1B1F',
    'primary': '#C2C1FF',
    'primary-darken-1': '#4B48DC',
    'secondary': '#C2C1FF',
    'secondary-darken-1': '#E68967',
    'error': '#FFB4AB',
    'info': '#4B48DC',
    'success': '#6ABF79',
    'warning': '#FFA93D',
    'text': '#C6C5D0',
  },
  variables: {
    'border-color': '#E2DFFF',
    'border-opacity': 1,
    'high-emphasis-opacity': 0.87,
    'medium-emphasis-opacity': 0.60,
    'disabled-opacity': 0.38,
    'idle-opacity': 0.04,
    'hover-opacity': 0.04,
    'focus-opacity': 0.12,
    'selected-opacity': 0,
    'activated-opacity': 0,
    'pressed-opacity': 0.12,
    'dragged-opacity': 0.08,
  },
}

export default defineNuxtPlugin((app) => {
  const vuetify = createVuetify({
    theme: {
      defaultTheme: 'myCustomLightTheme',
      themes: {
        myCustomLightTheme,
        variables: {
          fontFamily: 'Plus Jakarta Sans, sans-serif',
        },
      },
    },
  })
  app.vueApp.use(vuetify)
})
