import { default as _91_id_93szfri5jjiPMeta } from "/app/pages/apps/[_id].vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91_id_9329V0si54rzMeta } from "/app/pages/organizations/[_id].vue?macro=true";
import { default as sign_45outE9PR0xFy9HMeta } from "/app/pages/sign-out.vue?macro=true";
import { default as unauthorizedUnTSWnoGh4Meta } from "/app/pages/unauthorized.vue?macro=true";
export default [
  {
    name: "apps-_id",
    path: "/apps/:_id()",
    meta: _91_id_93szfri5jjiPMeta || {},
    component: () => import("/app/pages/apps/[_id].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "organizations-_id",
    path: "/organizations/:_id()",
    meta: _91_id_9329V0si54rzMeta || {},
    component: () => import("/app/pages/organizations/[_id].vue").then(m => m.default || m)
  },
  {
    name: "sign-out",
    path: "/sign-out",
    meta: sign_45outE9PR0xFy9HMeta || {},
    component: () => import("/app/pages/sign-out.vue").then(m => m.default || m)
  },
  {
    name: "unauthorized",
    path: "/unauthorized",
    meta: unauthorizedUnTSWnoGh4Meta || {},
    component: () => import("/app/pages/unauthorized.vue").then(m => m.default || m)
  }
]