/**
 * Fetch session and populate the global accessToken with the session's accessToken
 *
 * NOTE: We need to fetch both on the server and on initial client load because other middlewares
 * might need the the token server-side and the data we set on the server does not get passed to
 * the client
 */
export default defineNuxtRouteMiddleware(async () => {
  const nuxtApp = useNuxtApp()
  const isInitialClientLoad = import.meta.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered

  if (isInitialClientLoad || import.meta.server) {
    const { getSession, accessToken } = useAuthAdapter()

    const session = await getSession()

    accessToken.value = session.accessToken
  }
})
