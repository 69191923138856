import type { SecondarySignInOptions, SignOutOptions } from '@sidebase/nuxt-auth/dist/runtime/types'

const accessToken = ref<string | undefined>(undefined)

export const useAuthAdapter = () => {
  const {
    status,
    data,
    lastRefreshedAt,
    getCsrfToken,
    getProviders,
    getSession,
    signIn: __signIn,
    signOut: __signOut,
  } = useAuth()

  const isLoading = ref(false)
  const isAuthenticated = ref(false)

  async function signIn(signInOptions?: SecondarySignInOptions) {
    await __signIn('auth0', signInOptions, {
      prompt: 'login',
    })
  }

  async function signUp(signInOptions?: SecondarySignInOptions) {
    await __signIn('auth0', signInOptions, {
      prompt: 'login',
      screen_hint: 'signup',
    })
  }

  async function signOut(options?: SignOutOptions) {
    await __signOut({
      callbackUrl: '/',
      ...options,
    })
    accessToken.value = undefined
  }

  async function sendPasswordResetEmail() {
    if (!data.value?.user?.email) {
      throw new Error('User is not authenticated or email is not available')
    }

    const email = data.value.user.email

    try {
      const response = await $fetch('/api/auth/reset-password', {
        method: 'POST',
        body: { email },
      })

      return response
    }
    catch (error) {
      console.error(error)
      throw error
    }
  }

  watch(status, (newStatus) => {
    isLoading.value = newStatus === 'loading'
    isAuthenticated.value = newStatus === 'authenticated'
  }, {
    immediate: true,
  })

  watch(status, (newStatus) => {
    isLoading.value = newStatus === 'loading'
    isAuthenticated.value = newStatus === 'authenticated'
  }, {
    immediate: true,
  })

  return {
    accessToken,
    status,
    isLoading,
    isAuthenticated,
    data,
    lastRefreshedAt,
    getCsrfToken,
    getProviders,
    getSession,
    signIn,
    signUp,
    signOut,
    sendPasswordResetEmail,
  }
}
